input[type="checkbox"] {
    visibility: visible;
}

input[type="checkbox"]+label:before {
    border: 1px solid #333;
    content: "\00a0";
    display: inline-block;
    font: 16px/1em sans-serif;
    height: 16px;
    margin: 0 .25em 0 0;
    padding: 0;
    vertical-align: top;
    width: 16px;
    background-color: blue;
}

input[type="checkbox"]:checked+label:before {
    background: red;
    color: green;
    content: "\2713";
    text-align: center;
}

.fVYqjU {
    background-color: #F8F9FA !important;
}
.sidebar {
    position: fixed;
    top: 0;
    height: 100vh;
    transition: right 0.3s ease-in-out;
    z-index: 9999;
    border-radius: 20px 0px 0px 20px;
    background: var(--neutral-white, #FFF);
    box-shadow: -5px 0px 20px 0px rgba(0, 0, 0, 0.15);

}

.sidebar.open {
    right: 0;
}

.sidebar.close {
    right: -100% !important;
    width: 0%;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
   }
   