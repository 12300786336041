@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  @font-face {
    font-family: Avenir;
    src: url(../public/fonts/Avenir-Regular.ttf);
  }

  html {
    font-family: 'Avenir', 'Work Sans', sans-serif !important;
  }

}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.my-input::placeholder {
  padding: 10px;
  color: #737E93;
}

.CircularProgressbar .CircularProgressbar-text {
  font-size: 30px !important;
}

.highcharts-root {
  border-radius: 10px !important;
}

.spanclasses {
  font-weight: 600;
}

input[type="radio"] {
  accent-color: #E31837;
}

input[type="checkbox"] {
  accent-color: #E31837;
}

.custom-border-radius {
  border-radius: 10px !important;
}