.react-tel-input .form-control:focus {
    border:  2px solid black;
    outline: none; 
}
.react-tel-input .flag-dropdown {
    border-radius: 6px 0 0 6px;
    border-right: none;
}
.react-tel-input .form-control:focus + .flag-dropdown {
    border: 2px solid black;
    border-right: none;
}